@import url("https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,500,500i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Exo+2:200,200i,300,300i,400,400i,500,500i,700,700i");

$baseline: 8px;

// colors
$politica-cookies-primary-green: #3fa110;
$politica-cookies-primary-text-color: #323c32;
$politica-cookies-secondary00-background: #fff;

// font family
$politica-cookies-title-font-family: "Exo 2", "Exo-2", sans-serif;
$politica-cookies-paragraph-font-family: "Nunito", sans-serif;

// font size
$politica-cookies-primary-title-font-size: 3rem;
$politica-cookies-secondary-title-font-size: 2.5rem;
$politica-cookies-paragraph-font-size: 1rem;
$politica-cookies-sub-title-font-size: 1.125rem;

/* stylelint-disable */
:export {
  politica-cookies-primary-green: $politica-cookies-primary-green;
  politica-cookies-primary-text-color: $politica-cookies-primary-text-color;
  politica-cookies-title-font-family: $politica-cookies-title-font-family;
  politica-cookies-paragraph-font-family: $politica-cookies-paragraph-font-family;
  politica-cookies-primary-title-font-size: $politica-cookies-primary-title-font-size;
  politica-cookies-secondary-title-font-size: $politica-cookies-secondary-title-font-size;
  politica-cookies-paragraph-font-size: $politica-cookies-paragraph-font-size;
  politica-cookies-sub-title-font-size: $politica-cookies-sub-title-font-size;
}
