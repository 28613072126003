@import "../../common/StyleVariables/variables.scss";

#politica-cookies-root {
  background-color: $politica-cookies-secondary00-background;
  overflow-x: hidden;
  color: $politica-cookies-primary-text-color;

  .layout-container {
    width: 64vw !important;
    margin: $baseline auto !important;
    max-width: none;

    .main-title {
      font-family: $politica-cookies-title-font-family;
      font-style: italic;
      font-weight: 400 !important;
      line-height: 1.5;
    }

    .sub-title {
      font-family: $politica-cookies-title-font-family;
      font-size: $politica-cookies-sub-title-font-size;
    }

    .paragraph,
    .cookies-data-paragraph {
      font-weight: 400 !important;
      font-size: $politica-cookies-paragraph-font-size;
      text-align: left;
      font-family: $politica-cookies-paragraph-font-family;
      line-height: $baseline * 3;
    }

    .bold-text {
      font-weight: bolder !important;
    }

    .list-marker-item {
      color: $politica-cookies-primary-green;
    }

    .list-marker-item-text {
      font-family: $politica-cookies-title-font-family;
      color: $politica-cookies-primary-text-color;
    }

    .green-highlighted-link {
      color: $politica-cookies-primary-green !important;
      cursor: pointer;
      text-decoration: none;
      font-family: $politica-cookies-title-font-family !important;
    }

    .what-are-cookies-section,
    .what-cookies-finalities-section,
    .what-type-cookies-we-use-section,
    .how-long-which-cookies-section,
    .how-to-eliminate-cookies-section,
    .how-we-treat-data-div {
      margin-bottom: $baseline * 4;
    }

    .what-are-cookies-section,
    .what-type-cookies-we-use-section {
      .paragraph:not(:last-child) {
        padding-bottom: $baseline * 2;
      }
    }

    .how-long-which-cookies-section {
      .paragraph:nth-child(2) {
        padding-bottom: $baseline * 2;
      }
    }

    .what-type-cookies-we-use-section {
      .paragraph:nth-child(4) {
        padding-bottom: 0;
      }
    }

    .what-cookies-finalities-section,
    .what-type-cookies-we-use-section {
      ul {
        margin-bottom: $baseline * 2;

        .list-marker-item {
          padding-bottom: $baseline;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .layout-container {
      width: 72vw !important;
    }
  }
}
