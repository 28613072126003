#politica-cookies-root {
  .cookie-display {
    display: flex;
    flex-direction: column;

    span {
      width: fit-content;
    }
  }

  .bold-text {
    font-weight: bolder !important;
  }
}
